@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // font-family: 'Montserrat', sans-serif;
    @apply bg-body-background;
}

*:focus {
    outline: none !important;
}