.Toastify__toast {
    border-radius: 0 !important;
    color: white;

    &--success {
        @apply bg-green-500;
    }

    &--error {
        @apply bg-red-500;
    }

    &--warning {
        @apply bg-yellow-500;
    }

    &--info {
        @apply bg-blue-500;
    }
}